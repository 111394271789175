// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Otis Kit PRO components
import MKBox from "components/MKBox";

// Otis Kit PRO examples
import ColoredBackgroundCard from "examples/Cards/BackgroundCards/ColoredBackgroundCard";

function Features() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={4} mt={4}>
          <Grid item xs={12} md={6} lg={4}>
            <ColoredBackgroundCard
              color="light"
              title="智能AI算法"
              image=""
              description="高度灵活的自研架构可以轻松搭建前沿人工智能算法"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ColoredBackgroundCard
              color="light"
              title="微秒级内部延迟"
              image=""
              description="深度优化的C++底层设计，将内部延迟降至最低。"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ColoredBackgroundCard
              color="light"
              title="安全 &nbsp; 稳定 &nbsp; 可靠"
              image=""
              description="支持本地/私有云部署，极高的策略保密性和安全性。系统经过大量极端压力测试，稳定可靠。"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Features;
