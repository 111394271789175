// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Otis Kit PRO components
import MKBox from "components/MKBox";

// Otis Kit PRO examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function Products() {
  return (
    <MKBox component="section" py={{ xs: 6, md: 12 }} mt={-3}>
      <Container>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: "auto" }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="electric_bolt"
                title="KData 极速数据接口"
                description="为投资者提供优质数据服务"
              />
              <SimpleInfoCard
                icon="speed"
                title="KTrader 核心量化基座"
                description="为投资者提供超低延迟量化操作系统"
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 0 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="psychology"
                title="KAlgo 人工智能算法"
                description='为投资者供AI"先知"智囊团'
              />
              <SimpleInfoCard
                icon="construction"
                title="KLib 专业量化工具箱"
                description="为投资者提供多样的辅助武器库"
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0 }, ml: { xs: 0, md: 0 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="cloud"
                title="KCloud 云端交易平台"
                description="为投资者供分布式云端交易平台"
              />
              <SimpleInfoCard
                icon="tune"
                title="KControl 综合资管平台"
                description="为投资者供全局鹰眼监控管理系统"
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Products;
