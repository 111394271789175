import Footer from "sections/Footer";
import NavBars from "sections/NavBars";

import Card from "@mui/material/Card";

import Posts from "sections/updates/posts";

function updates() {
  return (
    <>
      <NavBars />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 4,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
        }}
      >
        <Posts />
      </Card>
      <Footer />
    </>
  );
}

export default updates;
