import Navbars from "sections/NavBars";
import Container from "@mui/material/Container";
import { useState, useEffect, React } from "react";
import { useParams } from "react-router-dom";
import Iframe from "react-iframe";
// import pxToRem from "assets/theme/functions/pxToRem";

function Docs() {
  const [iFrameHeight, setiFrameHeight] = useState("");
  const [src, setSrc] = useState("");
  const srcPath = /zenquant-docs/;
  const params = useParams();
  useEffect(() => {
    setTimeout(() => {
      setSrc(srcPath + params.name);
      setiFrameHeight(`${window.innerHeight - 80}px`);
    }, 1000);
  }, []);

  return (
    <>
      <Navbars />
      <Container sx={{ mb: 2, ml: "-24px" }}>
        <Iframe
          title="ktrader-doc"
          id="ktrader-doc-frame"
          src={src}
          frameBorder="0"
          width="100%"
          height={iFrameHeight}
          allowFullScreen="true"
        />
      </Container>
    </>
  );
}
export default Docs;
