import Footer from "sections/Footer";
import NavBars from "sections/NavBars";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import docs from "routes/doc_route";

function documents() {
  return (
    <>
      <NavBars />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: 4,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container>
          <MKBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="xl"
            mt={0}
            mb={3}
            p={2}
            sx={{}}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} md={12} textAlign="center">
                <MKTypography variant="h3" color="text" fontWeight="bold">
                  用户文档
                </MKTypography>
              </Grid>

              {docs.map((doc) => (
                <Grid item xs={12} md={12} textAlign="left">
                  <MKTypography
                    variant="body1"
                    color="text"
                    fontWeight="regular"
                    component={Link}
                    to={doc.route}
                  >
                    * {doc.name}
                  </MKTypography>
                </Grid>
              ))}
            </Grid>
          </MKBox>
        </Container>
      </Card>
      <Footer id="footer" />
    </>
  );
}
export default documents;
