import DocPage from "pages/docs/Index";

const docRoutes = [
  {
    name: "Klib 使用说明",
    route: "/docs/klib-intro.html",
    component: <DocPage />,
  },
  {
    name: "Klib API文档",
    route: "/docs/klib-api.html",
    component: <DocPage />,
  },
  {
    name: "KTrader Python 策略开发指南",
    route: "/docs/ktrader-python-guide.html",
    component: <DocPage />,
  },
  {
    name: "KTrader Python API 文档",
    route: "/docs/ktrader_python_api.html",
    component: <DocPage />,
  },
  {
    name: "KTrader本地回测配置",
    route: "/docs/backtest-local.html",
    component: <DocPage />,
  },
];

export default docRoutes;
