// @mui material components
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import links from "routes/nav_route";
import QRCode from "image/QR.jpg";

function Footer() {
  const date = new Date().getFullYear();

  const copyright = (
    <MKTypography variant="button" color="secondary">
      Copyright &copy;{date}{" "}
      <MKTypography
        component="a"
        href="/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        color="secondary"
      >
        易量科技
      </MKTypography>
      .
    </MKTypography>
  );

  return (
    <MKBox component="footer" py={6}>
      <Grid container justifyContent="center">
        <Grid item xs={10} lg={8}>
          <Stack
            direction="row"
            flexWrap="wrap"
            justifyContent="center"
            spacing={{ xs: 2, lg: 3, xl: 6 }}
            mb={3}
          >
            {links.map((link, key) => {
              const elementKey = `${link.name}-${key}`;
              return (
                <MKTypography
                  key={elementKey}
                  component={Link}
                  href={link.route}
                  variant="body2"
                  color="secondary"
                  fontWeight="regular"
                >
                  {link.name}
                </MKTypography>
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Stack display="flex" direction="row" justifyContent="center" spacing={2} mt={1} mb={3}>
            <img src={QRCode} alt="QRCode" width="160" />
            <Divider orientation="vertical" style={{ height: "auto" }} />
            <Stack display="flex" direction="column" justifyContent="center">
              <MKTypography variant="button" color="secondary">
                北京易量科技有限公司
              </MKTypography>
              <MKTypography variant="button" color="secondary">
                电子邮件：
                <MKTypography
                  component="a"
                  href="mailto:support@zenquant.cn"
                  target="_blank"
                  rel="noreferrer"
                  variant="button"
                  color="secondary"
                >
                  support@zenquant.cn
                </MKTypography>
              </MKTypography>
              <MKTypography variant="button" color="secondary">
                地址：北京市海淀区知春路23号量子银座大厦
              </MKTypography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
          {copyright}
        </Grid>
      </Grid>
    </MKBox>
  );
}

export default Footer;
