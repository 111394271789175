import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Otis Kit PRO themes
import theme from "assets/theme";

// Ktrader pag
import KTraderPage from "pages/ktrader";
import KTraderSignIn from "pages/signIn";
import KTraderDocuments from "pages/documents";
import KTraderAbout from "pages/about";
import KTraderProfile from "pages/profile";
import DocPage from "pages/docs/Index";
import KtraderUpdates from "pages/updates";

import { AuthClientProvider } from "@authing/react-ui-components";
import { AuthenticationClient } from "authing-js-sdk";

import "@authing/react-ui-components/lib/index.min.css";

// 初始化 AuthenticationClient
const authClient = new AuthenticationClient({
  // 替换你的 AppId
  appId: "62a931464235a3940c9955d7",
});

export default function App() {
  const { pathname } = useLocation();
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const mytheme = responsiveFontSizes(theme);
  mytheme.typography.h1 = {
    fontSize: "2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.8rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3.6rem",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "5rem",
    },
  };

  return (
    <ThemeProvider theme={mytheme}>
      <CssBaseline />
      <AuthClientProvider client={authClient}>
        <Routes>
          <Route path="/docs/:name" element={<DocPage />} />
          <Route path="/" element={<KTraderPage />} key="uniquevalue" />
          <Route path="/sign-in" element={<KTraderSignIn />} key="signin" />
          <Route path="/documents" element={<KTraderDocuments />} key="documents" />
          <Route path="/profile" element={<KTraderProfile />} key="profile" />
          <Route path="/about" element={<KTraderAbout />} key="about" />
          <Route path="/updates" element={<KtraderUpdates />} key="updates" />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AuthClientProvider>
    </ThemeProvider>
  );
}
