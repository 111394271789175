// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimplePricingCard from "examples/Cards/PricingCards/SimplePricingCard";

function Pricing() {
  return (
    <MKBox id="products" component="section" py={{ xs: 0, md: 10 }} mt={-6}>
      <Container>
        <Grid container spacing={3} mt={3}>
          <Grid item xs={12} sm={6} lg={12 / 5}>
            <SimplePricingCard
              color="dark"
              title="KTrader Basic 体验版"
              description="免费试用3月"
              price={{ value: "0元", type: "年" }}
              specifications={[
                { name: "Tick历史数据时长: 一年(2021.06-2022.06)", status: "done" },
                { name: "本地部署/云端部署", status: "done" },
                { name: "基础量化工具包", status: "done" },
                { name: "机器学习工具包", status: "clear" },
                { name: "策略开发语言: Python3", status: "done" },
                { name: "支持系统: Linux (ubuntu 20.04)", status: "done" },
                { name: "覆盖市场: 中国期货", status: "done" },
                { name: "回测支持: 云端一年回测/每天10次", status: "done" },
                { name: "实盘支持", status: "clear" },
                { name: "多账户", status: "clear" },
                { name: "适合策略: 中低频", status: "done" },
                { name: "客户服务: 社群", status: "done" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={12 / 5}>
            <SimplePricingCard
              color="dark"
              title="KTrader Cloud 云端版"
              description="即将上线"
              price={{ value: "20000元", type: "年" }}
              specifications={[
                { name: "Tick历史数据时长: 2016-至今", status: "done" },
                { name: "云端接口", status: "done" },
                { name: "基础量化工具包", status: "done" },
                { name: "机器学习工具包", status: "clear" },
                { name: "策略开发语言: Python3", status: "done" },
                { name: "支持系统: Windows, Mac, Linux", status: "done" },
                { name: "覆盖市场: 中国期货", status: "done" },
                { name: "回测支持: 云端", status: "done" },
                { name: "实盘支持: 仅云端", status: "done" },
                { name: "多账户", status: "clear" },
                { name: "适合策略: 中低频", status: "done" },
                { name: "客户服务: 社群", status: "done" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={12 / 5}>
            <SimplePricingCard
              // variant="gradient"
              color="dark"
              title="KTrader Solo 个人版"
              description="已上线"
              price={{ value: "50000元", type: "年" }}
              specifications={[
                { name: "Tick历史数据时长: 2016-至今", status: "done" },
                { name: "本地部署/云端部署 ", status: "done" },
                { name: "基础量化工具包", status: "done" },
                { name: "机器学习工具包", status: "clear" },
                { name: "策略开发语言: Python3", status: "done" },
                { name: "支持系统: Linux (ubuntu 20.04)", status: "done" },
                { name: "覆盖市场: 中国期货", status: "done" },
                { name: "回测支持: 本地无限制/云端", status: "done" },
                { name: "实盘支持: 本地/云端", status: "done" },
                { name: "多账户", status: "clear" },
                { name: "适合策略: 中低频", status: "done" },
                { name: "客户服务: 社群", status: "done" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={12 / 5}>
            <SimplePricingCard
              color="dark"
              title="KTrader Pro 专业版"
              description="即将上线"
              price={{ value: "100000元", type: "年" }}
              specifications={[
                { name: "Tick历史数据时长: 2016-至今", status: "done" },
                { name: "本地部署/私有云部署 ", status: "done" },
                { name: "基础量化工具包", status: "done" },
                { name: "机器学习工具包", status: "clear" },
                { name: "策略开发语言: Python3 & C++", status: "done" },
                { name: "支持系统: Linux (ubuntu 20.04)", status: "done" },
                { name: "覆盖市场: 中国期货", status: "done" },
                { name: "回测支持: 无限制", status: "done" },
                { name: "实盘支持: 本地/云端", status: "done" },
                { name: "多账户，多策略，可分仓", status: "done" },
                { name: "适合策略: 低中高频", status: "done" },
                { name: "客户服务: VIP专线服务", status: "done" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={12 / 5}>
            <SimplePricingCard
              color="dark"
              title="KTrader Ultimate 企业版"
              description="联系客服"
              price={{ value: "请联系客服咨询", type: "" }}
              specifications={[
                { name: "Tick历史数据时长: 2016-至今", status: "done" },
                { name: "本地部署/私有云部署 ", status: "done" },
                { name: "基础量化工具包", status: "done" },
                { name: "机器学习工具包", status: "done" },
                { name: "策略开发语言: Python3 & C++", status: "done" },
                { name: "支持系统: Linux (ubuntu 20.04)", status: "done" },
                { name: "覆盖市场: 中国期货", status: "done" },
                { name: "回测支持: 无限制", status: "done" },
                { name: "实盘支持: 本地/云端", status: "done" },
                { name: "多账户，多策略，可分仓", status: "done" },
                { name: "适合策略: 低中高超高频，适配多个极速柜台接口", status: "done" },
                { name: "客户服务: 24/7专线服务", status: "done" },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}
export default Pricing;
