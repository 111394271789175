import Footer from "sections/Footer";
import NavBars from "sections/NavBars";

import MKBox from "components/MKBox";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";

import logoLarge from "image/small_white.png";
import Timeline from "sections/Timeline";
import { useState, useEffect, React } from "react";

function about() {
  const [backgroundWidth, setBackgroundWidth] = useState("1800px");
  const [backgroundMargin, setBackgroundMargin] = useState("");
  const [backgroundHightUpdater, setBackgroundHightUpdater] = useState(0);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 400) {
        setBackgroundHightUpdater((768 - window.innerWidth) * 0.7);
      } else if (window.innerWidth < 768) {
        setBackgroundHightUpdater((768 - window.innerWidth) * 0.5);
      }
      if (window.innerWidth < 1800) {
        setBackgroundWidth(`${window.innerWidth + 200}px`);
        setBackgroundMargin("0 !important");
      }
      if (window.innerWidth > 1800) {
        setBackgroundWidth(`1800px`);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);
  return (
    <>
      <NavBars />
      {/* <Header /> */}

      <Container
        direction="row"
        alignItems="center"
        justify="center"
        sx={{ padding: "0px!important", margin: "0px!important" }}
      >
        <Stack display="flex" direction="row" justifyContent="center" p={6}>
          <MKBox
            textAlign="left"
            justifyContent="space-between"
            alignItems="flex-start"
            width="1000px"
          >
            <MKTypography variant="h3" color="INFO" fontWeight="bold">
              公司介绍 <br />
              COMPANY INFO
            </MKTypography>
          </MKBox>
        </Stack>

        <Stack direction="row" justifyContent="flex-start" ml="0 !important" mr={backgroundMargin}>
          <MKBox
            width="2100px"
            variant="gradient"
            bgColor="dark"
            height={`${400 + backgroundHightUpdater}px`}
          />
        </Stack>

        <Stack display="flex" direction="row" justifyContent="center" p={6}>
          <Box
            textAlign="left"
            mt={`${-400 - backgroundHightUpdater}px`}
            justifyContent="space-between"
            alignItems="center"
            width="1000px"
            height="400px"
          >
            {" "}
            <img src={logoLarge} alt="logo-large" style={{ width: "auto", height: 70 }} />
            <MKTypography variant="body1" color="white" fontWeight="bold">
              易量科技是一家以数据驱动和人工智能为核心的科技公司，致力于成为世界顶级的算法交易服务商。易量科技深度融合金融与科技，围绕人工智能，打造新一代的KTrader智能算法交易投资平台3.0，降低技术门槛、拓展AI投资边界。易量科技将不断创新，并持续为个人交易者、券商、对冲基金、公募基金、资管机构等众多大型金融机构提供稳定、安全、可靠、智能的算法交易解决方案。
            </MKTypography>
          </Box>
        </Stack>

        <Stack
          display="flex"
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          p={6}
          mt={-2}
        >
          <MKBox
            textAlign="left
            "
            justifyContent="space-between"
            alignItems="flex-end"
            width="800px"
          >
            <MKTypography variant="h3" color="INFO" fontWeight="bold">
              团队 <br />
              TEAM
            </MKTypography>
          </MKBox>
        </Stack>
        <Stack
          display="flex-end"
          direction="row"
          justifyContent="flex-end"
          mr="0!important"
          ml={backgroundMargin}
        >
          <MKBox
            width={backgroundWidth}
            variant="gradient"
            bgColor="dark"
            height={`${200 + backgroundHightUpdater}px`}
          />
        </Stack>

        <Stack
          display="flex"
          direction="row"
          justifyContent="center"
          mt={`${-210 - backgroundHightUpdater}px`}
          p={6}
        >
          <Stack
            display="flex"
            direction="row"
            justifyContent="flex-start"
            width="800px"
            height="200px"
          >
            <MKTypography variant="body1" color="white" fontWeight="bold">
              核心成员均来自北美和国内知名院校，有多年海外从业经验，对交易与科技有着深度热爱和极致追求。团队量化研究员和低延迟架构工程师在公司团队中占比超过90%。我们在算法交易领域有着领先的实盘业绩及丰富的交易系统搭建经验。
            </MKTypography>
          </Stack>
        </Stack>

        <Stack
          display="flex"
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          hight="200px"
          p={6}
          mt={`${-32 + backgroundHightUpdater}px`}
        >
          <MKBox
            textAlign="right"
            justifyContent="space-between"
            hight="200px"
            alignItems="flex-end"
            width="800px"
          >
            <MKTypography variant="h3" color="INFO" fontWeight="bold">
              愿景 <br />
              BELIEF
            </MKTypography>
          </MKBox>
        </Stack>

        <Stack direction="row" justifyContent="flex-start" mr={backgroundMargin}>
          <MKBox
            width={backgroundWidth}
            variant="gradient"
            bgColor="dark"
            height={`${200 + backgroundHightUpdater}px`}
          />
        </Stack>

        <Stack
          display="flex"
          direction="row"
          justifyContent="center"
          mt={`${-210 - backgroundHightUpdater}px`}
          p={6}
        >
          <Stack
            display="flex"
            direction="row"
            justifyContent="flex-start"
            width="800px"
            height="200px"
          >
            <MKTypography variant="body1" color="white" fontWeight="bold">
              我们是对交易与科技有着诚挚热爱和极致追求的极客。希望通过我们的不懈努力，打造出世界一流的科技产品，让量化投资容易可控，让更多人受益于AI与科技的进步，为提升市场有效性和流动性做出一份贡献
            </MKTypography>
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="flex-start" mt={`${20 + backgroundHightUpdater}px`}>
          <Timeline />
        </Stack>
      </Container>

      <Footer />
    </>
  );
}
export default about;
