import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";

import Footer from "sections/Footer";
import NavBars from "sections/NavBars";

import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import fetchProfile from "pages/fetchProfile";
import ShowMoreText from "react-show-more-text";

const user = fetchProfile();
function Profile() {
  try {
    if (user.read()) {
      console.log(user.read());
    } else {
      console.log("failed to read user");
      return <Navigate to="/" />;
    }
  } catch (prom) {
    console.log(prom);
  }
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <NavBars key="navBar" />
      <Suspense fallback={<h1>Loading profile...</h1>}>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: 4,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Container>
            <MKBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="xl"
              mt={0}
              mb={1}
              p={2}
              sx={{}}
            >
              <Grid container spacing={1} alignItems="left">
                <Grid item xs={12} md={12} textAlign="left">
                  <MKTypography variant="body" color="text" fontWeight="bold">
                    用户信息
                  </MKTypography>
                </Grid>
              </Grid>
            </MKBox>
            <MKBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={-3}
              mb={0}
              p={2}
              sx={{}}
            >
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} md={4}>
                  <Item> 用户名</Item>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Item>{user.read().name}</Item>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Item>用户邮箱</Item>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Item>{user.read().email}</Item>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Item>用户手机</Item>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Item>{user.read().phone === "" ? user.read().phone : "N/A"}</Item>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Item>用户地址</Item>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Item>{user.read().address === "" ? user.read().address : "N/A"}</Item>
                </Grid>
              </Grid>
            </MKBox>
            <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1} sx={{}}>
              {" "}
              <MKButton
                size="medium"
                variant="outlined"
                sx={{ mr: 1 }}
                color="dark"
                href="https://ktrader-official.authing.cn/u"
              >
                更新用户信息
              </MKButton>
            </MKBox>
          </Container>
        </Card>

        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: 4,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Container>
            <MKBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderRadius="xl"
              mt={0}
              mb={1}
              p={2}
              sx={{}}
            >
              <Grid container spacing={1} alignItems="left">
                <Grid item xs={12} md={12} textAlign="left">
                  <MKTypography variant="body" color="text" fontWeight="bold">
                    账户使用
                  </MKTypography>
                </Grid>
              </Grid>
            </MKBox>
            <MKBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={-3}
              mb={3}
              p={2}
              sx={{}}
            >
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6} md={4}>
                  <Item> Token</Item>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Item>
                    <ShowMoreText
                      /* Default options */
                      lines={1}
                      more="更多"
                      less="Show less"
                      expanded={false}
                      truncatedEndingComponent="... "
                    >
                      {user.read().token}
                    </ShowMoreText>
                  </Item>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Item> Token 过期时间</Item>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Item>{user.read().tokenExpiredAt}</Item>
                </Grid>
              </Grid>
            </MKBox>
          </Container>
        </Card>
      </Suspense>
      <Footer />
    </>
  );
}

export default Profile;
