import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useState, useEffect } from "react";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
// import MKBox from "components/MKBox";
import { Link } from "react-router-dom";
import { useGlobalAuthClient } from "@authing/react-ui-components";
import pxToRem from "assets/theme/functions/pxToRem";

import pages from "routes/nav_route";
import logo from "image/small_white.png";

const settings = [
  {
    name: "用户中心",
    route: "/profile",
  },
  {
    name: "退出",
    route: "*",
    signOut: true,
  },
];

function Navbars() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();

  const authClient = useGlobalAuthClient();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const onLogout = async () => {
    const userInfo = await authClient.getCurrentUser();
    if (userInfo) {
      console.log("logging out ");
      await authClient.logout();
    }
    console.log("logging out ");
    localStorage.clear();
    setIsAuthenticated(false);
    window.location.reload(false);
  };

  const handleCloseUserMenu = (event) => {
    const signout = event.currentTarget.getAttribute("data-signout");
    if (signout === "true") {
      onLogout();
    }

    setAnchorElUser(null);
  };

  // 检查用户是否有登录态
  const checkLoginStatus = React.useCallback(async () => {
    const userInfo = await authClient.getCurrentUser();
    if (!userInfo) {
      return;
    }
    setUser(userInfo);
    setIsAuthenticated(true);
    console.log(JSON.stringify(userInfo));
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }, []);

  useEffect(() => {
    console.log("loading nav");
    checkLoginStatus();
  }, []);

  return (
    <AppBar position="sticky" color="dark" enableColorOnDark key="NavBar">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              height: 40,
              ml: 0.5,
            }}
          >
            <Link to="/">
              <img src={logo} alt="Logo" href="/" height="40" />
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
              PaperProps={{
                style: {
                  width: 150,
                  padding: `${pxToRem(8)} ${pxToRem(1)}`,
                },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={handleCloseNavMenu}
                  component={Link}
                  to={page.route}
                >
                  <MKTypography variant="button" textAlign="center" sx={{ fontSize: "1.2em" }}>
                    {page.name}
                  </MKTypography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            justifyContent="center"
            alignitems="center"
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                component={Link}
                onClick={handleCloseNavMenu}
                variant="contianed"
                alignitems="baseline"
                to={page.route}
                sx={{ px: "10px" }}
              >
                <MKTypography
                  variant="button"
                  fontWeight="medium"
                  textTransform="capitalize"
                  color="white"
                  sx={{ letterSpacing: 16, fontSize: "1.2em", fontWeight: "1%", ml: 1, mr: 1.5 }}
                >
                  {page.name}
                </MKTypography>
              </Button>
            ))}
          </Box>

          <Box sx={{ width: "160px" }} textAlign="right">
            {!isAuthenticated ? (
              <Box sx={{ flexGrow: 0 }}>
                <MKButton
                  size="small"
                  variant="outlined"
                  sx={{ mr: 1 }}
                  component={Link}
                  to="/sign-in"
                >
                  登录/注册
                </MKButton>
              </Box>
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="用户">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <MKAvatar src={user.photo} alt="avatar" size="sm" />
                    <MKTypography
                      variant="button"
                      fontWeight="medium"
                      textTransform="capitalize"
                      color="white"
                      sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
                    >
                      {user.username}
                    </MKTypography>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "40px", mr: "-1px" }}
                  id="menu-appbar2"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  PaperProps={{
                    style: {
                      width: 150,
                      padding: `${pxToRem(8)} ${pxToRem(1)}`,
                    },
                  }}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.name}
                      component={Link}
                      to={setting.route}
                      onClick={handleCloseUserMenu}
                      data-signout={setting.signOut}
                    >
                      <MKTypography variant="button" textAlign="left" sx={{ fontSize: "1.2em" }}>
                        {setting.name}
                      </MKTypography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbars;
