/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function ColoredBackgroundCard({ color, image, label, title, description }) {
  return (
    <Card
      sx={({
        palette: { gradients },
        functions: { rgba, linearGradient },
        borders: { borderRadius },
      }) => ({
        backgroundImage: `${linearGradient(
          rgba(gradients[color] ? gradients[color].main : gradients.info.main, 0.9),
          rgba(gradients[color] ? gradients[color].state : gradients.info.state, 0.9)
        )}, url(${image})`,
        backgroundSize: "section",
        backgroundPosition: "center",
        borderRadius: borderRadius.xl,
        height: "100%",
        display: "grid",
      })}
    >
      <MKBox textAlign="center" p={4} my={6}>
        {label && (
          <MKTypography
            display="block"
            variant="caption"
            color={color === "light" ? "text" : "white"}
            textTransform="uppercase"
            opacity={0.8}
            fontWeight="bold"
            mb={2}
          >
            {label}
          </MKTypography>
        )}
        <MKTypography variant="h3" color={color === "light" ? "dark" : "white"}>
          {title}
        </MKTypography>
        <MKTypography
          variant="body1"
          color={color === "light" ? "text" : "white"}
          opacity={0.8}
          mb={3}
        >
          {description}
        </MKTypography>
      </MKBox>
    </Card>
  );
}

// Setting default values for the props of ColoredBackgroundCard
ColoredBackgroundCard.defaultProps = {
  color: "info",
  label: "",
  image: "",
};

// Typechecking props for the ColoredBackgroundCard
ColoredBackgroundCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
};

export default ColoredBackgroundCard;
