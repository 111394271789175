const navRoutes = [
  {
    name: "产品",
    route: "/products",
  },
  {
    name: "公告",
    route: "/updates",
  },
  {
    name: "关于",
    route: "/about",
  },
  {
    name: "文档",
    route: "/documents",
  },
];

export default navRoutes;
