import MKBox from "components/MKBox";

import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";

import timeline from "image/timeline.png";
import { useState, useEffect, React } from "react";

function Timeline() {
  const [backgroundWidth, setBackgroundWidth] = useState("1000px");
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1000) {
        setBackgroundWidth(`${window.innerWidth - 50}px`);
      }
      if (window.innerWidth > 1000) {
        setBackgroundWidth(`1000px`);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <MKBox width="100%" variant="gradient" bgColor="dark" p={6}>
      <Stack
        display="flex"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        p={6}
        mt={-2}
      >
        <MKBox
          textAlign="left
            "
          justifyContent="space-between"
          alignItems="flex-end"
          width={backgroundWidth}
        >
          <MKTypography variant="h3" color="white" fontWeight="bold">
            发展历程 <br />
            MILESTONE
          </MKTypography>
        </MKBox>
      </Stack>
      <Stack display="flex" direction="row" justifyContent="center" alignItems="flex-start" p={6}>
        <img src={timeline} alt="timeline" width={backgroundWidth} />
      </Stack>
    </MKBox>
  );
}

export default Timeline;
