/**
=========================================================
* Otis Kit PRO - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Guard, AuthClientProvider, useGlobalAuthClient } from "@authing/react-ui-components";
import Grid from "@mui/material/Grid";

// import AWS from "aws-sdk";
// import { isNull } from "util";

// const appUrl = "ktrader-official";
// const idpUrl = `${appUrl}.authing.cn/oidc`;

function SignIn() {
  const authClient = useGlobalAuthClient();

  const onLogin = () => {
    window.location.href = "/";
  };

  // const setAwsCred = () => {
  //   const awsCred = localStorage.getItem("awsCred");
  //   if (!isNull(awsCred) && awsCred !== "undefined") {
  //     console.log("awsCred: %o", awsCred);
  //   } else {
  //     const idToken = localStorage.getItem("idToken");
  //     console.log("idToken:%s", idToken);
  //     console.log("Start reqeust to AWS resources");
  //     console.log("idp_url: %s ", idpUrl);
  //     // Initialize the Amazon Cognito credentials provider
  //     AWS.config.region = "cn-north-1"; // Region
  //     AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //       IdentityPoolId: "cn-north-1:5f0b5bde-e7f1-45d5-a87f-c7e20cb381be",
  //       Logins: {
  //         "ktrader-official.authing.cn/oidc": idToken,
  //       },
  //     });

  //     AWS.config.credentials.get((err, cred) => {
  //       if (!err) {
  //         console.log(AWS.config.credentials);
  //         console.log("retrieved identity: %s ", AWS.config.credentials.identityId);
  //         localStorage.setItem("awsCred", cred);
  //       } else {
  //         console.error("error retrieving identity: %s", err);
  //         // alert("error retrieving identity " + err);
  //       }
  //     });
  //   }
  // };

  return (
    <Grid container justifyContent="center" alignItems="center" minHeight="100vh" display="flex">
      <AuthClientProvider client={authClient}>
        <Guard Guard onLogin={onLogin} />
      </AuthClientProvider>
    </Grid>
  );
}

export default SignIn;
