// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
// import bgImage from "image/main.jpg";
import bgImage from "image/polygon-backtround-3000x2000.jpg";

// page sections
import Features from "sections/Features";
import Products from "sections/Products";
// import LogoArea from "sections/LogoArea";
import Footer from "sections/Footer";
import Navbars from "sections/NavBars";
import Pricing from "sections/Pricing";
import FAQ from "sections/FAQ";

function Ktrader() {
  return (
    <>
      <Navbars />
      <MKBox
        minHeight="70vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container style={{ width: "100vw" }}>
          <Grid container>
            <Grid item xs={12} justifyContent="center" textAlign="center" mx="auto">
              <MKTypography variant="h1" color="white" fontWeight="bold">
                KTrader智能投资平台3.0
              </MKTypography>
              <MKTypography variant="h3" color="white" fontWeight="medium">
                为投资者和机构提供顶尖的投资的利器
              </MKTypography>
              <MKTypography variant="h3" color="white" fontWeight="regular">
                Build by Quant Traders, for Quant Traders
              </MKTypography>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      <Features />
      <Products />
      <Pricing />
      <FAQ />

      <Footer />
    </>
  );
}

export default Ktrader;
