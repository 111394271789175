import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelpCenter page components
import FaqCollapse from "examples/HelpCenter/components/FaqCollapse";

const faqs = [
  {
    question: "KTrader是否收费？",
    answer:
      "产品刚上线，获得邀请码的朋友，可以免费试用3个月（附赠3个月的Tick级别历史数据）,试用期结束后会收费。",
  },
  {
    question: "KTrader是否可以实盘？",
    answer:
      "KTrader Python 是一款策略研究回测工具，目前不支持实盘，但用户使用KTrader Python开发出的策略代码是可以直接放在实盘系统KTrader 上运行，无缝衔接。KTrader 实盘功能预计在Q4上线, 具备微秒级别系统延迟，和KTrader Python采用相同的Python策略开发接口，附加更强大的机器学习开发工具。",
  },
  {
    question: "使用KTrader开发策略是否安全？策略保密性如何？",
    answer:
      "策略保密性方面客户可以完全放心。客户的数据，策略，隐私安全是我们最看重的。所有敏感数据的传输我们都有做加密处理，保障客户数据安全。对于策略安全性要求较高的客户以选购KTrader Pro 或 KTrader Ultimate. ，我们也可以做私有化本地部署或私有云部署，做到数据层面上的物理隔绝。",
  },
];

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6} my={6}>
            <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
              常见问题
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={10}>
            {faqs.map((faq, idx) => (
              <FaqCollapse
                title={faq.question}
                open={collapse === idx}
                onClick={() => (collapse === idx ? setCollapse(false) : setCollapse(idx))}
              >
                <MKTypography variant="body2"> {faq.answer} </MKTypography>
              </FaqCollapse>
            ))}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
