// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";

// Otis Kit PRO components
import Post from "sections/updates/post";

// Images
import hireImage from "image/updates/hire_image.png";
import introImage from "image/updates/intro_image.png";

function Posts() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <Post
                image={introImage}
                title="10分钟快速上手KTrader Python"
                description="我们是对交易和科技有着诚挚热爱和极致追求的极客。KTrader团队致力于打造一款低延迟、支持机器学习的工业级别量化交易系统，旨在降低算法交易的技术门槛，提升策略开发效率和实盘效果，让量化交易员/研究员可以专注于策略开发，而不用去顾虑琐碎繁杂的系统维护工作，做到易用性和高性能兼顾，专为实战交易打造。"
                date="2022/6/27"
                route="https://mp.weixin.qq.com/s/Xbc6CU-S1myDE6sebV5rrQ"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <Post
                image={hireImage}
                title="易量科技 KTrader 招聘"
                date="2022/7/6"
                description="
                招聘岗位：量化研究员（CTA方向） 招聘人数：2人 


                公司所有核心成员均来自北美和国内知名顶级院校，并有多年海外从业经验，团队量化研究员和低延迟架构工程师在公司团队中占比超过90%。我们执着于深度融合交易与科技，并在算法交易领域有着丰富的低延迟系统搭建和交易实战经验。"
                route="https://mp.weixin.qq.com/s/QPLfDetLHUHmE7hyghAg5w"
              />
            </MKBox>
          </Grid>
        </Grid>
        <MKBox mt={5}>
          <MKPagination>
            <MKPagination item>
              <Icon>keyboard_arrow_left</Icon>
            </MKPagination>
            <MKPagination item active>
              1
            </MKPagination>
            {/* <MKPagination item>2</MKPagination>
            <MKPagination item>3</MKPagination>
            <MKPagination item>4</MKPagination>
            <MKPagination item>5</MKPagination> */}
            <MKPagination item>
              <Icon>keyboard_arrow_right</Icon>
            </MKPagination>
          </MKPagination>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default Posts;
